// App.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PasswordPage from "./components/PasswordPage";
import MainContent from "./components/MainContent";
import { auth, db } from "./firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import bcrypt from "bcryptjs";
import axios from "axios";

const AppContainer = styled.div`
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #031424;
  color: #fff;
  min-height: 100vh;
`;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      console.log("Auth State Changed:", currentUser);
      if (currentUser) {
        console.log("User ID:", currentUser.uid);
        console.log("User Email:", currentUser.email);
        setIsAuthenticated(true);
        setUser(currentUser);
      } else {
        console.log("No user is signed in.");
        setIsAuthenticated(false);
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Failed to get user IP address:", error);
      return null;
    }
  };

  const sendDiscordWebhook = async (username, newIP, oldIP) => {
    try {
      const webhookUrl =
        "https://discord.com/api/webhooks/1268222555811086348/q0MQh5dcSv3ZZ3f8F6glv_WSV1itLwroTrDG4JKT9p6XT_Ma9_A7MNK12B8h17O3plvs";
      const hasNewIP = oldIP && oldIP !== newIP;
      const embed = {
        title: "User Login Detected",
        description: `Username: **${username}**\nCurrent IP: **${newIP}**`,
        color: hasNewIP ? 0xe74c3c : 0x3498db, // Red if IP changed, otherwise blue
        fields: [],
        timestamp: new Date(),
      };
      if (hasNewIP) {
        embed.fields.push({ name: "Previous IP", value: oldIP });
        embed.fields.push({ name: "Notice", value: "**NEW IP ADDRESS USED**" });
      }

      await axios.post(webhookUrl, {
        embeds: [embed],
      });
    } catch (error) {
      console.error("Failed to send Discord webhook:", error);
    }
  };

  const handlePasswordSubmit = async (username, password, setError) => {
    setError("");
    try {
      const q = query(
        collection(db, "users"),
        where("username", "==", username)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("Incorrect username or password. Please try again.");
        return;
      }

      let foundUser = null;
      let userDocId = null;
      querySnapshot.forEach((doc) => {
        foundUser = doc.data();
        userDocId = doc.id;
      });

      if (foundUser) {
        const passwordMatch = await bcrypt.compare(
          password,
          foundUser.password
        );
        if (passwordMatch) {
          const userIP = await getUserIP();

          const previousIP = foundUser.lastLoginIP;
          if (userIP !== previousIP) {
            console.log("IP address has changed.");
          }

          const userDocRef = doc(db, "users", userDocId);
          // Update the database with the user's IP and set isLoggedOut to false
          await setDoc(
            userDocRef,
            { lastLoginIP: userIP, isLoggedOut: false },
            { merge: true }
          );

          await sendDiscordWebhook(username, userIP, previousIP);

          setIsAuthenticated(true);
          setUser({ ...foundUser, uid: userDocId });
        } else {
          setError("Incorrect username or password. Please try again.");
        }
      } else {
        setError("Incorrect username or password. Please try again.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const checkUserStatus = async () => {
      if (user && user.username) {
        try {
          const q = query(
            collection(db, "users"),
            where("username", "==", user.username)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              const userData = doc.data();
              if (userData.isLoggedOut) {
                alert("You have been logged out. Please log in again.");
                setIsAuthenticated(false);
                setUser(null);
              }
            });
          }
        } catch (error) {
          console.error("Error checking user status:", error);
        }
      }
    };

    const interval = setInterval(checkUserStatus, 10000); // Check every 10 seconds
    return () => clearInterval(interval);
  }, [user, setIsAuthenticated, setUser]);

  if (loading) return <div>Loading...</div>;

  return (
    <AppContainer>
      {isAuthenticated ? (
        <MainContent user={user} />
      ) : (
        <PasswordPage onLogin={handlePasswordSubmit} />
      )}
    </AppContainer>
  );
}

export default App;
