// PasswordPage.js
import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #031424;
  color: #fff;
`;

const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #10253a;
  color: #fff;
  &:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(187, 134, 252, 0.5);
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: #03dac6;
  color: #121212;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #018786;
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SubText = styled.p`
  font-size: 12px;
  color: #bbb;
  margin-top: 10px;
  text-align: center;
`;

const PasswordPage = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Fetch the user's IP address
  const getUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Failed to get user IP address:", error);
      return null;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userIP = await getUserIP(); // Fetch the IP address
    onLogin(username, password, setError, userIP); // Pass IP to onLogin
  };

  return (
    <PasswordContainer>
      <PasswordForm onSubmit={handleSubmit}>
        <TextInput
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <TextInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <SubmitButton type="submit">Submit</SubmitButton>
        <SubText>
          Your IP address will be stored in order to prevent abuse and to
          discourage the sharing of your private login.
        </SubText>
      </PasswordForm>
    </PasswordContainer>
  );
};

export default PasswordPage;
