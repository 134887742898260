import { initializeApp } from "firebase/app";
import { getFirestore, setLogLevel } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCvEFeT9_Ak1M_THVWgBVW_aRYeOzUi1W4",
  authDomain: "crtyx-shopfiy.firebaseapp.com",
  projectId: "crtyx-shopfiy",
  storageBucket: "crtyx-shopfiy.appspot.com",
  messagingSenderId: "124055801099",
  appId: "1:124055801099:web:c94b4cceea6d75aa00a60e",
  measurementId: "G-SET6X0FFW1",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

setLogLevel('silent');

const auth = getAuth(app);

export { db, auth };
